<template>
  <el-drawer title="直营门店" append-to-body :visible.sync="show" :with-header="false" size="70%">
    <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :expand-on-click-node="false" default-expand-all :empty-text="loading ? '正在加载…' : '没有数据'" @node-click="handleNodeClick" :style="{height: height + 'px', overflow: 'auto'}">
      <div class="h c" slot-scope="{data}" :title="data.name">
        <i :class="treeIcons[data.type]"></i>
        <div>&nbsp;{{data.name}}</div>
      </div>
    </el-tree>
  </el-drawer>
</template>

<script>
import initData from "@/mixins/initData";

export default {
  mixins: [initData],
  props: {
    value: Number | String,
    type: String,
    visible: Boolean,
  },
  watch: {
    visible: function (val) {
      this.show = val;
    },
    show: function (val) {
      if (!val) this.$emit("update:visible", false);
    },
  },
  data() {
    return {
      show: false,
      height: "",
      treeIcons: {
        group: "fa fa-file-alt",
        company: "fa fa-file-alt",
        subcompany: "fa fa-file-alt",
        area: "fa fa-file-alt",
        shop: "fa fa-file-alt",
      },
    };
  },
  mounted() {
    this.init();
    this.height = window.innerHeight;
  },
  methods: {
    beforeInit() {
      this.url = "api/analy/shop/shopTree";
      this.params = { sort: "id,desc" };
      return true;
    },
    buildNodeKey(source) {
      (source || []).forEach((o) => {
        o._id = o.type + "_" + o.id;
        this.buildNodeKey(o.children);
      });
    },
    afterLoad(res) {
      if (res && res.length) {
        this.buildNodeKey(res);
        if (res && res.length) {
          let autoSelect = res[0];
          this.$nextTick(() => {
            this.$refs.tree && this.$refs.tree.setCurrentKey(autoSelect._id);
            this.handleNodeClick(autoSelect, true);
          });
        }
      } else {
        res = [];
      }
      res.unshift({
        children: null,
        id: "1",
        _id: "1_group",
        isDelete: false,
        name: "所有",
        parentId: null,
        type: "group",
      });
      return res;
    },
    handleNodeClick(data, init = false) {
      if (data.id && data.type) {
        this.show = false;
        this.$emit("input", data.id);
        this.$emit("update:type", data.type);
        this.$emit("change", data);
        if (init) {
          this.$emit("init");
        }
      }
    },
  },
};
</script>
