<template>
  <el-drawer class="h5-drawer" title="选择分类" append-to-body :visible.sync="show" size="75%">
    <el-tree :data="data" :props="treeProps" highlight-current node-key="id" :expand-on-click-node="false" @current-change="handleChange" />
  </el-drawer>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  mixins: [initData],
  props: {
    value: Number | String,
    label: String,
    visible: Boolean,
  },
  watch: {
    visible: function (val) {
      this.show = val;
    },
    show: function (val) {
      if (!val) this.$emit("update:visible", false);
    },
    value: function(n, o) {
      if (n != o) {
        this.setSelect(n);
      }
    }
  },
  data() {
    return {
      selected: null,
      show: false,
      treeProps: {
        label: "name",
        isLeaf: "isLeaf"
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/category/tree";
      return true;
    },
    afterLoad(res) {
      let al = { id: null, name: "全部" };
      if (res && res.length) res.unshift(al);
      else return [al];
    },
    handleChange(e) {
      this.$emit("input", e.id);
      this.$emit("change", this.value, e.id)
      this.$emit("update:label", e.name);
      this.show = false;
    },
    setSelect(v) {
      this.$refs.tree && this.$refs.tree.setCurrentKey(v);
    },
  },
  mounted() {
    this.init();
  }
};
</script>