<template>
  <el-drawer class="h5-drawer with-vflex" title="选择商品" :visible.sync="show" size="80%">
    <el-input v-model="query.keywords" clearable placeholder="商品名称" :validate-event="false" @keyup.enter.native="toQuery">
      <el-button slot="append" icon="el-icon-search" @click.stop="toQuery" />
    </el-input>
    <el-table ref="list" row-key="id" :data="data" v-loading="loading" :show-header="false" height="200px" @selection-change="handleSelectionChange" @row-click="handleRowClick">
      <el-table-column type="selection" width="35" reserve-selection v-if="multiple" />
      <el-table-column label="商品">
        <div slot-scope="scope" style="line-height: 1.3;">
          <div>{{scope.row.name}}</div>
          <div class="fc-g">{{$goodsSpecConvert(scope.row.specs)}}</div>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination class="h5-pagination" :total="total" :current-page="page + 1" :pager-count="5" layout="prev, pager, next" @current-change="pageChange" />
    <div class="padding-10 h c m">
      <el-button type="primary" round :disabled="loading || !selection || !selection.length" @click="handleSubmit" v-if="multiple">确定</el-button>
      <el-button type="danger" round :disabled="loading || !selection || !selection.length" @click="handleSubmitClear">清除选择</el-button>
    </div>
  </el-drawer>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  mixins: [initData],
  props: {
    visible: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    visible: function (val) {
      this.show = val;
    },
    show: function (val) {
      if (!val) this.$emit("update:visible", false);
    },
  },
  data() {
    return {
      show: false,
      selection: null,
      query: {
        keywords: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    beforeInit() {
      this.url = "api/goods/sku";
      this.params = this.query;
      return true;
    },
    handleRowClick(row) {
      if (this.multiple) {
        this.$refs.list && this.$refs.list.toggleRowSelection(row);
      } else {
        this.$emit("change", row);
        this.show = false;
      }
    },
    handleSelectionChange(s) {
      this.selection = s;
    },
    handleSubmitClear() {
      this.$emit("change", this.multiple ? [] : null);
      this.show = false;
    },
    handleSubmit() {
      if (this.multiple) {
        this.$emit("change", this.selection);
        this.show = false;
      }
    },
  },
};
</script>