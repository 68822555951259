<template>
  <div class="shopMain">
    <div class="c h sb sep-t padding-05" @click="drawer.shop = true">
      <div class="no-flex padding-05">直营门店</div>
      <div class="flex ta-c"></div>
      <div>
        <span>{{ store.shopTitle }}</span>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showDimension">
      <div class="no-flex padding-10">分析维度</div>
      <el-radio-group v-model="query.dateGroup" size="mini">
        <el-radio label="year">年</el-radio>
        <el-radio label="month">月</el-radio>
        <el-radio label="day">日</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sb sep-t">
      <div class="no-flex padding-10">时间范围</div>
      <div class="c h">
        <div class="h c" key="range-year" v-if="query.dateGroup === 'year'">
          <el-date-picker v-model="query.begDate" size="mini" type="year" value-format="timestamp" placeholder="开始年" :clearable="false" :editable="false" style="width: 2.4rem" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="year" value-format="timestamp" placeholder="结束年" :clearable="false" :editable="false" style="width: 2.4rem" />
        </div>
        <div class="h c" key="range-month" v-else-if="query.dateGroup === 'month'">
          <el-date-picker v-model="query.begDate" size="mini" type="month" value-format="timestamp" placeholder="开始月" :clearable="false" :editable="false" style="width: 2.4rem" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="month" value-format="timestamp" placeholder="结束月" :clearable="false" :editable="false" style="width: 2.4rem" />
        </div>
        <div class="h c" key="range-date" v-else-if="query.dateGroup === 'day'">
          <el-date-picker v-model="query.begDate" size="mini" type="date" value-format="timestamp" placeholder="开始日" :clearable="false" :editable="false" style="width: 2.4rem" />
          <div class="padding-0-05">至</div>
          <el-date-picker v-model="query.endDate" size="mini" type="date" value-format="timestamp" placeholder="结束日" :clearable="false" :editable="false" style="width: 2.4rem" />
        </div>
      </div>
    </div>
    <div class="c h sep-t" v-if="showOpt.showYearonyear">
      <div class="flex padding-10">开启同比</div>
      <el-date-picker v-model="query.compareYear" type="year" placeholder="选择年" size="mini" style="width: 2rem" :clearable="false" :editable="false" v-if="query.yearOnYearShow" />
      <el-switch v-model="query.yearOnYearShow" />
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showMonth">
      <div class="no-flex padding-10">开启环比</div>
      <el-switch v-model="query.compareMonth" />
    </div>
    <div class="c h sb sep-t" v-if="query.dateGroup !== 'year' && showOpt.showCompareyear">
      <div class="no-flex padding-10">同比年度</div>
      <el-input-number v-model="query.compareYear" :min="2000" :max="2099" :step="1" :precision="0" placeholder="输入年份" style="width: 2.4rem" />
    </div>
    <template v-if="showOpt.showRangGoods">
      <div class="c h sep-t padding-05" @click="drawer.brand = true">
        <div class="no-flex padding-05">商品品牌</div>
        <div class="flex ta-r padding-05">{{ label.brand }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.series = true" v-if="query.brandId">
        <div class="no-flex padding-05">商品系列</div>
        <div class="flex ta-r padding-05">{{ label.series }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.category = true" v-if="showOpt.showGoodsType">
        <div class="no-flex padding-05">商品分类</div>
        <div class="flex ta-r padding-05">{{ label.category }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </template>
    <template v-if="showOpt.showCategory">
      <div class="c h sep-t padding-05" @click="drawer.category = true">
        <div class="no-flex padding-05">对比类型1</div>
        <div class="flex ta-r padding-05">{{ label.category }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
      <div class="c h sep-t padding-05" @click="drawer.category2 = true" v-if="query.categoryId">
        <div class="no-flex padding-05">对比类型2</div>
        <div class="flex ta-r padding-05">{{ label.categoryB }}</div>
        <i class="el-icon-arrow-right padding-05 fc-g"></i>
      </div>
    </template>
    <div class="c h sep-t padding-05" @click="drawer.goods = true" v-if="showOpt.showCommodity">
      <div class="no-flex padding-05">指定商品</div>
      <div class="flex ta-r padding-05">{{ label.goods }}</div>
      <i class="el-icon-arrow-right padding-05 fc-g"></i>
    </div>
    <div class="c h sb sep-t" v-if="showOpt.showRank">
      <div class="no-flex padding-10">排名对象</div>
      <el-radio-group v-model="query.shopAreaGroup">
        <el-radio label="area">区域</el-radio>
        <el-radio label="shop">门店</el-radio>
        <el-radio label="salesman">销售人员</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showLevel">
      <div class="no-flex padding-10">汇总级别</div>
      <el-radio-group v-model="query.goodsGroup">
        <el-radio label="brandId">品牌结构</el-radio>
        <el-radio label="seriesId">系列结构</el-radio>
        <el-radio label="goodsCategoryId">分类结构</el-radio>
        <el-radio label="spuId">商品结构</el-radio>
      </el-radio-group>
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showReported">
      <div class="no-flex padding-10">仅显示已上报</div>
      <el-switch v-model="query.reportOnly" @change="handleReportOnlyChange" />
    </div>
    <div class="c h sep-t sb" v-if="showOpt.showRankGist">
      <div class="no-flex padding-10">排名依据</div>
      <div>
        <el-radio-group v-model="query.saleValueGroup" v-if="query.reportOnly">
          <el-radio label="checkSaleNumber">销售数量</el-radio>
          <el-radio label="realCheckSumMoney">销售金额</el-radio>
          <el-radio label="checkSendNumber">发货数量</el-radio>
          <el-radio label="checkSendMoney">发货金额</el-radio>
        </el-radio-group>
        <el-radio-group v-model="query.saleValueGroup" v-else>
          <el-radio label="saleNumber">销售数量</el-radio>
          <el-radio label="realSumMoney">销售金额</el-radio>
          <el-radio label="sendNumber">发货数量</el-radio>
          <el-radio label="sendMoney">发货金额</el-radio>
        </el-radio-group>
      </div>
    </div>
    <!-- 选直营门店 -->
    <shop-list :visible.sync="drawer.shop" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" @init="handleInited" />
    <!-- 选指定商品 -->
    <sku-selector request-url="api/goods/sku" :visible.sync="drawer.goods" @change="handleGoodsSelect" />
    <!-- 商品范围 -->
    <div class="c h padding-05">
      <brand-selector v-model="query.brandId" :label.sync="label.brand" :visible.sync="drawer.brand" @change="handleBrandSelect" />
      <series-selector v-model="query.seriesId" :label.sync="label.series" :brand-id="query.brandId" :visible.sync="drawer.series" />
      <category-selector key="cate1" v-model="query.categoryId" :label.sync="label.category" :visible.sync="drawer.category" />
      <category-selector key="cate2" v-model="query.categoryIdB" :label.sync="label.categoryB" :visible.sync="drawer.category2" />
    </div>
  </div>
</template>

<script>
import shopList from "../shopList";
import skuSelector from "../selector/skuSelect";
import brandSelector from "../selector/brandSelect";
import seriesSelector from "../selector/seriesSelect";
import categorySelector from "../selector/categorySelect";

export default {
  props: {
    showOpt: Object,
    query: Object,
  },
  components: {
    shopList,
    skuSelector,
    brandSelector,
    seriesSelector,
    categorySelector,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      label: {
        brand: "全部",
        series: "全部",
        category: "全部",
        categoryB: "全部",
        goods: "全部",
      },
      goods: {
        brandName: null,
        seriesName: null,
        categoryName: null,
        categoryAllname: null,
      },
      drawer: {
        shop: false,
        goods: false,
        brand: false,
        series: false,
        category: false,
        category2: false,
      },
      store: {
        shopTitle: "全部",
      },
    };
  },
  methods: {
    handleInited() {
      this.$emit("init");
    },
    handleShopChange(data) {
      this.store.shopTitle = data ? data.name : "全部";
    },
    handleGoodsSelect(res) {
      this.query.goods = res;
      if (res && res.length) {
        this.label.goods = res.map((o) => o.name).join("、");
      } else {
        this.label.goods = "全部";
      }
    },
    handleBrandSelect(res) {
      if (this.query.hasOwnProperty("seriesId")) {
        this.query.seriesId = null;
        this.label.series = "全部";
      }
      // if (res) {
      //   this.goods.brandName = res;
      //   this.query.brandId = res.id;
      // }
    },
    handleSeriesChange(res) {
      if (res) {
        this.goods.seriesName = res;
        this.query.seriesId = res.id;
        this.drawer.series = false;
      }
    },
    // handleCategoryChange(res) {
    //   if (res) {
    //     this.goods.categoryName = res;
    //     this.query.categoryId = res.id;
    //     this.drawer.category = false;
    //   }
    // },
    // handleCategoryChange2(res) {
    //   if (res) {
    //     this.goods.categoryAllname = res;
    //     this.query.categoryIdB = res.id;
    //     this.drawer.category2 = false;
    //   }
    // },
    handleReportOnlyChange(val) {
      if (this.showOpt.showRankGist) {
        if (val == true) {
          this.query.saleValueGroup = "realCheckSumMoney";
        } else {
          this.query.saleValueGroup = "realSumMoney";
        }
      }
    },
  },
};
</script>